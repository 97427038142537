import React, { lazy, Suspense, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { LoadingGif } from "./components/elements/Loading";
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser, logout } from "./actions/register";
import PrivateRoute from "./utils/routing/PrivateRoute";
import Navbar from "./components/newNavbar";
import Footer from "./components/footer";
import Groups from "./components/groups";
import "./components/styles/main.css";
import { signInWithGoogle } from "./firebase";
import { auth } from "./firebase";
import ScrollToTop from "./scroll";

const Alerts = lazy(() => import("./components/elements/Alerts"));
const Login = lazy(() => import("./components/AuthPages/Login"));
const Register = lazy(() => import("./components/AuthPages/Register"));
const Confirm = lazy(() => import("./components/AuthPages/Confirm"));
const Forgot = lazy(() => import("./components/AuthPages/Forgot"));

const Landing = lazy(() => import("./components/Landing"));

const Home = lazy(() => import("./components/Pages/Home"));
const Aboutus = lazy(() => import("./components/Pages/AboutUs"));

const Faq = lazy(() => import('./components/Pages/faq'));
const Shipping = lazy(() => import('./components/Pages/ship/shipping'));
const Category = lazy(() => import('./components/Pages/category'));
const Cart = lazy(() => import('./components/Pages/Carts'));
const Dashboard = lazy(() => import("./components/Pages/dashboard/mydashboard"));
const StartCampaign = lazy(() => import("./components/Pages/startCampaign/landing"));
const Product = lazy(() => import("./components/Pages/Product/productpage"));
const ProductUpcoming = lazy(() => import("./components/Pages/ProductUpcoming/productpage"));
const Legal = lazy(() => import("./components/Pages/Legal/legal"));
const Whatwedo = lazy(() => import("./components/Pages/whatWeDo/whatwedo"));
const Career = lazy(() => import("./components/Pages/career/career"));

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  /*useEffect(() => {
    store.dispatch(loadUser());
    const handleInvalidToken = (e) => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        store.dispatch(logout());
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);*/

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
        <Suspense fallback={LoadingGif}>
          <Fragment>
            <Navbar/>
            <section style={{paddingTop: "80px"}}>
              <Alerts />
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/forgot" component={Forgot} />
                <Route exact path="/confirm/:token" component={Confirm} />
                <Route exact path="/aboutus" component={Aboutus} />
                <Route exact path="/" component={Landing} />

                <Route exact path="/cart/:perkId" component={Cart} />

                <Route exact path="/category" component={Category} />

                <Route exact path="/ship/:perkId" component={Shipping} />

                <Route exact path="/Faq" component={Faq} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/startcampaign" component={StartCampaign} />
                <Route exact path="/product/:projectId" component={Product} />
                <Route exact path="/upcomingproduct/:projectId" component={ProductUpcoming} />
                <Route exact path="/legal/:doc" component={Legal} />
                <Route exact path="/whatwedo" component={Whatwedo} />
                <Route exact path="/career" component={Career} />
                <PrivateRoute exact path="/home" component={Home} />
                <Redirect from="*" to="/" />
              </Switch>
            </section>
            <Footer />
          </Fragment>
        </Suspense>
      
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default App;
