import React from 'react'
import './styles/styling.min.css'
import { Link } from "react-router-dom";

const Footer = () => {  
    return (
        <footer className="Footer_bottom">
            <div className="container">
  
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <h2 className="Footer_row1_col1_heading">Sign Up To Our Newsletter.</h2>
                  <h4 className="Footer_row1_col1_text">Be The First To Hear About The Latest Offers.</h4>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <input type="text" name="#" placeholder="Your Email" className="Footer_email_newsletter" />
                </div>
                <div className="col-lg-3 col-sm-6">
                  <button type="button" name="Footer_button_Subscribe" className="Footer_row1_col3_button"><span className="Footer_row1_col3_button_text">Subscribe</span></button>
                </div>
              </div>
            </div>
  
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 Footer_row2_col1">
                  <h3 className="Footer_row2_col1_heading">Legal</h3>
                  <h4 className="Footer_row2_col1_content"><Link to="/legal/termsOfUse">Terms of use</Link></h4>
                  <h4 className="Footer_row2_col1_content"><Link to="/legal/privacyPolicy">Privacy Policy</Link></h4>
                  <h4 className="Footer_row2_col1_content"><Link to="/legal/refundPolicy">Refund Policy</Link></h4>
                  <h4 className="Footer_row2_col1_content"><Link to="/legal/campaignOwnerAgreement">Campaign Owner Agreement</Link></h4>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <h3 className="Footer_row2_col1_heading">ABOUT</h3>
                  <h4 className="Footer_row2_col1_content"><Link to="/aboutus">About Us</Link></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Blog</a></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Trust & Safety</a></h4>
                  <h4 className="Footer_row2_col1_content"><Link to="/faq">Help & Support</Link></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Press</a></h4>
                  <h4 className="Footer_row2_col1_content"><Link to="/career">Careers</Link></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Contact</a></h4>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <h3 className="Footer_row2_col1_heading">Entrepreneurs</h3>
                  <h4 className="Footer_row2_col1_content"><a href="#">How It Works</a></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Kickstarter vs FundingX</a></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Education Centre</a></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Experts Directory</a></h4>
                  <h4 className="Footer_row2_col1_content"><a href="http://startups.fundingx.in/pricing.html">Fees</a></h4>
                  <h4 className="Footer_row2_col1_content"><a href="#">Enterperise</a></h4>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <h3 className="Footer_row2_col1_heading">Contact Us</h3>
                  <h4 className="Footer_row2_col1_content">Phone: <a href="+917982423491">+917982423491</a></h4>
                  <h4 className="Footer_row2_col1_content">Email: <a href="connec@fundingx.in">connect@fundingx.in</a></h4>
                </div>
              </div>
  
              {/*<div className="row Footer_row3">
  
                <div className="col-lg-2 col-sm-0">
  
                </div>
                <div className="col-lg-2 col-sm-2">
                  <h6 className="Footer_row3_content"><Link to="/legal/termsOfUse">Term Of Use</Link></h6>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <h6 className="Footer_row3_content"><Link to="/legal/privacyPolicy">Privacy Policy</Link></h6>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <h6 className="Footer_row3_content"><a href="#">Cookie Policy</a></h6>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <h6 className="Footer_row3_content"><a href="#">Do Not Sell My Personal Information</a></h6>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <h6 className="Footer_row3_content"><a href="#"><i className="fa fa-copyright"> 2021 FundingX All Right Reserved</i> </a></h6>
                </div>
    </div>*/}
  
              <div className="row Footer_row4">
                <div className="col-lg-12 col-sm-12">
                </div>
              </div>
  
              <div className="row Footer_row5">
                <div className="col-lg-4 col-sm-4">
  
                  <div className="row">
                    <div className="col-lg-1 col-sm-1 Footer_row5_content"><a href="#"><i className="fa fa-facebook-square"></i></a></div>
                    <div className="col-lg-1 col-sm-1 Footer_row5_content"><a href="#"><i className="fa fa-instagram"></i></a></div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 Footer_row5_content">
  
                </div>
                <div className="col-lg-4 col-sm-4 Footer_row5_content">
                  Copyright <i className="fa fa-copyright"> </i> FundingX is owned by VisionaryX Innovations Pvt. Ltd.
                </div>
              </div>
            </div>
          </footer>
    );
}

export default Footer;
