// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from "axios";

const config = {
  apiKey: "AIzaSyAOXaaaz5nFeCN6iuIshgE-7lZKBJNmjck",
  authDomain: "fundingx-28c2e.firebaseapp.com",
  projectId: "fundingx-28c2e",
  storageBucket: "fundingx-28c2e.appspot.com",
  messagingSenderId: "1026806458241",
  appId: "1:1026806458241:web:e3da5e73ed7e19e87daa80",
  measurementId: "G-SB08729WMG"
};

firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => {
  return auth.signInWithPopup(provider).then(user => {
    console.log("inFunc",user)
    if (user.additionalUserInfo.isNewUser === true){
      auth.currentUser.getIdToken(true).then(function(idToken){
        axios.post("/api/user/check",{authorization: idToken}).then(response => {
          //console.log(response);
          
        })
      })
    }
    alert("Crowdfunding is not shopping it is like believing in a startup in an early stage, and pre-booking its product at an early stage before the product hits the market or retail stores.");
    return user;
  });
  
}

export const signInWithGoogleNoPopUp = () => {
  auth.signInWithRedirect(provider).then(user => {
    if (user.additionalUserInfo.isNewUser === true){
      auth.currentUser.getIdToken(true).then(function(idToken){
        axios.post("/api/user/terms",{authorization: idToken}).then(response => {
          //console.log(response);
          return false
        })
      })
    }
    alert("Crowdfunding is not shopping it is like believing in a startup in an early stage, and pre-booking its product at an early stage before the product hits the market or retail stores.");
  });
  
}

export const reAuthAndDelete = (user) => {
  user.user.reauthenticateWithPopup().then(user=>user.user.delete())
}

export default firebase;