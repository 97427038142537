import React from "react";
import "./styles/navbar.css";

import { Link } from "react-router-dom";
import Logo from "./assets/img/navbar/FundingXonly.png";
import { signInWithGoogle } from "../firebase";
import { auth, reAuthAndDelete } from "../firebase";
import RegisterPopUp from "./signup";
import Dialog from 'react-bootstrap-dialog'
import axios from "axios";
import DialogAction from "react-bootstrap-dialog/dist/DialogAction";



class Navs extends React.Component {
  state = {
    currentUser: null,
    clicked:false
  };

  handleClick=()=>{
    this.setState({clicked: !this.state.clicked})
  }

  unsubscribeFromAuth = null;

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });
      //console.log(user);
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }





  render() {
    const mystyle = {
      borderRadius: "30px",
      display: "inline",
      
    };
    const styless = {
      right: "0px",
      left: "auto",
    };

    


    return (

      <div className="navbar-main-container">
          <nav className="navbar-items" >
<div className="menu-icon hidedesktop" onClick={this.handleClick}>
              <i className={this.state.clicked ? 'fas fa-times': 'fas fa-bars'}></i>
          </div>
            

         <div className="navbar-logo-container">
          
            <Link to="/" className="navbar-logo">
            <img className="logo" src={Logo} alt="not available" />
            </Link>

          </div>

        

            <div className="navbar-menu-bg">
              <div className={this.state.clicked? 'nav-menu active': 'nav-menu'}>

                
                <ul className="navbar-ul-container" >
                  <li className="nav-item m-1">  
                      <Link className="nav-link" to="/aboutus" >ABOUT US</Link>   
                  </li>
                  <li className="nav-item m-1">
                    <Link className="nav-link" to="/whatwedo" >HOW IT WORKS</Link>
                  </li>
                  <li className="nav-item m-1">
                    <a className="nav-link" href="https://startups.fundingx.in">START A CAMPAIGN</a>
                  </li>
                </ul>

                <div
                  className="navbar-search-container"  
                          >
                  <form className="navbar-form-container">
                    
                      <input
                        className="navbar-search-box"
                        type="search"
                        placeholder="Search"

                      />
                      
                        <a
                          className="search-btn"
                        
                        >
                          <i class="fas fa-search"></i>
                        </a>

                    
                  </form>
                </div>
            </div>
          </div>

           
          <div className="user-info navbar-brand no-collapsablee">
          {this.state.currentUser ? (
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="profile-container">
                  <img className="profile-img"  src={this.state.currentUser.photoURL} style={mystyle} />
                  <div className="profile-container"
                    style={{
                      marginLeft: "-100%",
                      marginRight: "-100%",
                      textAlign: "center",
                    }}
                  >
                    
                  <p className="username">
                    {this.state.currentUser.displayName}
                  </p>
                    
                  </div>

                  
                
                  
                </div>
              </button>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link className="dropdown-item" to="/dashboard">
                  <span  className="dashboard-optn">
                    <i
                    aria-hidden="true"
                    ></i>
                    DASHBOARD
                  </span>
                </Link>
                <Link
                  to="/"
                  className="dropdown-item"
                  onClick={() => auth.signOut()}
                >
                  <span className="logout-optn">
                    
                    <i
                      aria-hidden="true"
                    ></i>
                    LOG OUT
                  </span>
                </Link>
              </div>
            </div>
          ) : (
          <button
            className="navbar-btn"
                  
                  onClick={()=>{
                    signInWithGoogle().then(user=>{
                      const body = <div><p>Thankyou for signing Up.</p>
                      <p>By clicking on accept button you accept our <Link>terms of usage</Link>, <Link>Privacy Policy</Link> and <Link>Campaign agreement</Link>.</p></div>

                      if(user.additionalUserInfo.isNewUser)this.dialog.show(
                        {
                            body: body,
                            
                            actions: [
                              Dialog.CancelAction(()=>{
                                //console.log(user);
                              }),
                              Dialog.DefaultAction("Accept",()=>{
                                user.user.getIdToken(true).then(function(idToken){
                                  axios.post("/api/user/terms",{authorization: idToken}).then(response => {
                                    //console.log(response);
                                    return false
                                  })
                                })
                              },)
                            ]
                          }
                    );
                    });
                  }}
                >
              
                  SIGN IN
                </button>
              )}
              </div>
            <Dialog ref={(el) => { this.dialog = el }} />  
        </nav>
      </div>  
    );
  }
}

export default Navs;
