import React from "react";
import {Link} from "react-router-dom";
import Dialog from 'react-bootstrap-dialog'

const body = <div><p>Thankyou for signing Up.</p>
<p>By clicking on accept button you accept our <Link>terms of usage</Link>, <Link>Privacy Policy</Link> and <Link>Campaign agreement</Link>.</p></div>


const RegisterPopUp = (user,dialog)=>{
    {
        if(user.additionalUserInfo.isNewUser)this.dialog.show(
            {
                body: body,
                bsSize: 'medium',
                actions: [
                  Dialog.CancelAction(()=>{
                    //console.log(user);
                    user.user.delete();
                  }),
                  Dialog.DefaultAction("Accept",()=>null,"btn-secondary")
                ]
              }
        );
      }
}

export default RegisterPopUp;